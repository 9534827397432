import React from 'react'
import { GoogleMap, useLoadScript} from '@react-google-maps/api'
import { useWindowSize } from 'react-use';

const google_api_key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const Map = () => {
    const { width } = useWindowSize();
    const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: google_api_key });

    const containerStyle = {
        width: '100%',
        height: width < 768 ? '350px' : '100%'
    };

    const center = { lat: 49.27648066660764, lng: -122.84316938030724 };
    if (loadError) {
        return <div>Error loading maps</div>;
    }
    if (!isLoaded) {
        return <div>loading...</div>
    } else {
        return (
            <GoogleMap zoom={18} center={center} mapContainerStyle={containerStyle}>
            </GoogleMap>
        )
    }
}

export default Map;