import React from 'react';
import { Image } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';

import gallery from '../data/gallery';

function Gallery() {
    return (
        <Carousel data-bs-theme="dark">
            {gallery.map((image, index) => (
            <Carousel.Item key={index}>
                    <Image className="d-block w-100" src={image.imageSrc} />
            </Carousel.Item>
            ))}
        </Carousel>
    );


}

export default Gallery;