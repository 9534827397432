import { useState} from 'react';
import { Navbar, Container, Nav, Offcanvas, Image } from 'react-bootstrap';
import './Header.css';

import Logo from '../assets/Logo_black.png';
import MenuPDF from '../assets/menu.pdf';

function Header() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const handleNavClick = (e, baseTargetId) => {
    e.preventDefault();
    const targetId = window.innerWidth <= 768 ? `${baseTargetId}-mobile` : baseTargetId;
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      const navbarHeight = document.querySelector('.fixed-top').offsetHeight;
      const offsetTop = targetElement.getBoundingClientRect().top + window.pageYOffset - navbarHeight;

      setExpanded(false);
      requestAnimationFrame(() => { 
        window.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        });
      });
    }
  };

  return (
    <>
      <Navbar expand="lg" className="fixed-top bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/"><Image src={Logo} /></Navbar.Brand>
          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-lg`}
            onClick={toggleExpanded}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="top"
            show={expanded}
            onHide={toggleExpanded}
          >
            <Offcanvas.Header closeButton onHide={() => toggleExpanded}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1">
              <Nav.Link href="/" onClick={() => setExpanded(false)}>Home</Nav.Link>
                <Nav.Link href="#about" onClick={(e) => handleNavClick(e, 'about')}>About</Nav.Link>
                <Nav.Link href="#contact" onClick={(e) => handleNavClick(e, 'contact')}>Contact</Nav.Link>
                <Nav.Link href={MenuPDF} target="_blank" rel="noreferrer" onClick={() => setExpanded(false)}>Menu</Nav.Link>
              </Nav>
                <a href="https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Rj5VHmFLDXHw6ZbCMoFi" target="_blank" rel="noreferrer" className="nav-btn btn">Online Order</a>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;