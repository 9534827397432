import React, { useState } from 'react';
import { Col, Image } from 'react-bootstrap';
import './Flow.css';

import gallery from '../data/gallery';

function Flow() {
    const [animate, setAnimate] = useState(true);
    const onStop = () => setAnimate(false);
    const onRun = () => setAnimate(true);

    return (
        <div className="wrapper">
            <div className="slide_container">
                <ul
                    className="slide_wrapper"
                    onMouseEnter={onStop}
                    onMouseLeave={onRun}
                >
                    <div
                        className={"slide original".concat(
                            animate ? "" : " stop"
                        )}
                    >
                        {gallery.map((image, index) => (
                            <li
                                key={index}
                            >
                                <Col key={index} className="flow-wrap item">
                                    <Image src={image.imageSrc} />
                                </Col>
                            </li>
                        ))}
                    </div>
                    <div
                        className={"slide clone".concat(animate ? "" : " stop")}
                    >
                        {gallery.map((image, index) => (
                            <li
                                key={index}
                            >
                                <Col key={index} className="flow-wrap item">
                                    <Image src={image.imageSrc} />
                                </Col>
                            </li>
                        ))}
                    </div>
                </ul>
            </div>
        </div>
    );


}

export default Flow;