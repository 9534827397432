import Image1 from '../assets/gallery/Image1.jpg';
import Image2 from '../assets/gallery/Image2.jpg';
import Image3 from '../assets/gallery/Image3.jpg';
import Image4 from '../assets/gallery/Image4.jpg';
import Image5 from '../assets/gallery/Image5.jpg';
import Image6 from '../assets/gallery/Image6.jpg';
import Image7 from '../assets/gallery/Image7.jpg';
import Image8 from '../assets/gallery/Image8.jpg';
import Image9 from '../assets/gallery/Image9.jpg';
// import Image10 from '../assets/gallery/Image10.jpg';
// import Image11 from '../assets/gallery/Image11.jpg';


const clients_partners = [
    {
      id:1,
      imageSrc: Image1,
      title: "Image 1",
    },
    {
      id:2,
      imageSrc: Image2,
      title: "Image 2",
    },
    {
      id:3,
      imageSrc: Image3,
      title: "Image 3",
    },
    {
      id:4,
      imageSrc: Image4,
      title: "Image 4",
    },
    {
      id:5,
      imageSrc: Image5,
      title: "Image 5",
    },
    {
      id:6,
      imageSrc: Image6,
      title: "Image 6",
    },
    {
      id:7,
      imageSrc: Image7,
      title: "Image 7",
    },
    {
      id:8,
      imageSrc: Image8,
      title: "Image 8",
    },
    {
      id:9,
      imageSrc: Image9,
      title: "Image 9",
    }
  ];

  export default clients_partners;