import './HomePage.css';
import React, { useEffect, useRef } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';


///////////////////////////////
// SECTION COMMON RESOURCES  //
///////////////////////////////
import Arrow from '../assets/Arrow.png';
///////////////////////////////
// SECTION 1: Video / images //
///////////////////////////////
import Section1_Main_Video from '../assets/MainVideo.mp4';
import Section1_Main_Image from '../assets/MainImage.png';
///////////////////////////////
// Importing Delivery images //
///////////////////////////////
import DoorDash from '../assets/DoorDash.png';
import SkipTheDishes from '../assets/SkipTheDishes.png';
import UberEats from '../assets/UberEats.png';
///////////////////////////////
// SECTION 2: ABOUT          //
///////////////////////////////
import Logo from '../assets/Logo_white.png';
import Section2_1 from '../assets/Section2_1.jpg';
import Section2_2 from '../assets/Section2_2.jpg';
import Section2_3 from '../assets/Section2_3.jpg';
import Section2_4 from '../assets/Section2_4.jpg';
import Section2_5 from '../assets/Section2_5.jpg';
///////////////////////////////
// SECTION 3: ORDER ONLINE   //
///////////////////////////////
import Section3 from '../assets/Section3.png';
///////////////////////////////
// SECTION 4: GALLERY        //
///////////////////////////////
import Flow from '../components/Flow';
import Gallery from '../components/Gallery';
///////////////////////////////
// SECTION 5: Location       //
///////////////////////////////
import Section5 from '../assets/Section5.jpg';
import MenuPDF from '../assets/menu.pdf';
import Map from '../components/Map';


function HomePage() {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  }, []);


  return (
    <>
      <div className="main">
        <Row className="section1">
          <Col>
            <div className="overlay-container main-image-container">
              {window.innerWidth < 1200 ? (
                <>
                  <Image src={Section1_Main_Image} fluid />
                </>
              ) : (
                <>
                  <video ref={videoRef} className="object-fit-contain" muted loop>
                    <source src={Section1_Main_Video} type="video/mp4" />
                  </video>
                </>
              )}
              <div className="overlay-centered-text">
                <h1 className="overlay-title">
                  We present a modern,<br />
                  innovative, and reverent <br />
                  take on Japanese cuisine<br />
                </h1>
                <h5>
                  SUSHI MODO - PORT MOODY
                </h5>
                <div className="mt-3 d-inline-flex delivery-wrapper">
                  <a href="https://www.ubereats.com/store/sushi-modo-port-moody/s6WysySNVH-Z5OKyFnw3cw?diningMode=DELIVERY" target='_blank' rel="noreferrer">
                    <Image src={UberEats} fluid />
                  </a>
                  <a href="https://www.skipthedishes.com/sushi-modo-saint-johns" target='_blank' rel="noreferrer">
                    <Image src={SkipTheDishes} fluid />
                  </a>
                  <a href="https://www.doordash.com/en-CA/store/sushi-modo-(port-moody)-port-moody-23737868/" target='_blank' rel="noreferrer">
                    <Image src={DoorDash} fluid />
                  </a>
                </div>
                <div>
                  <a href="https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Rj5VHmFLDXHw6ZbCMoFi" className="link-btn btn" target='_blank' rel="noreferrer">Online Order</a>
                </div>
                <div className='arrow-wrapper'>
                  <Image src={Arrow} fluid />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <span className="scroll-down" id="about-mobile" style={{visibility: 'hidden'}}></span>
        {/* Section2: About */}
        <Container className="section2" id="about">
          <div> 
          <Row id="header-wrapper">
            <div className='img-wrapper' id="logo">
              <Image src={Logo} fluid />
            </div>
            <div>
              <p>
                Our menu highlights the freshest and finest ingredients sourced from around the world to bring you the best yet affordable culinary experience.
              </p>
            </div>
          </Row>
          <Row className="description">
            <Col className='img-wrapper'>
              <Row>
                <Image src={Section2_1} />
              </Row>
              <Row>
                <Image src={Section2_2} />
              </Row>
            </Col>
            <Col className="content-wrapper">
              <h4>DEVELOPMENT</h4>
              <div>
                <p>Welcome to Sushi Modo, where tradition meets innovation in the heart of Vancouver's vibrant culinary scene. At Sushi Modo, we believe in the continuous development of our craft, blending authentic Japanese techniques with contemporary culinary trends to offer a truly unique dining experience.
                  Our journey in the art of sushi making is a story of dedication and passion. Inspired by the rich heritage of Japanese cuisine, our chefs strive to create dishes that not only tantalize the taste buds but also honor the centuries-old traditions of sushi craftsmanship.
                </p>
              </div>
            </Col>
            <Col className='img-wrapper'>
              <Row>
                <Image src={Section2_3} fluid />
              </Row>
              <Row>
                <Image src={Section2_4} fluid />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className="content-wrapper" style={{ textAlign: 'left' }}>
              <h4>FRESH. AUTHENTIC.</h4>
              <p>
                At Sushi Modo, freshness is our priority. We source the finest ingredients daily to ensure that every dish bursts with vibrant flavors and crisp textures. From the ocean to your plate, experience the difference that freshness makes in every bite.
                <br /><br />
                Dive into the authentic flavors of Japan at Sushi Modo.
                Our chefs are dedicated to preserving traditional Japanese culinary techniques while infusing each dish with the true essence of Japanese cuisine. Transport yourself to the streets of Tokyo with every mouthful of our authentic creations.
              </p>
            </Col>
            <Col>
              <div>
                <Image src={Section2_5} fluid />
              </div>
            </Col>
          </Row>
          </div>
        </Container>
        {/* Section3: Online Order  */}
        <Row className="section3" id="online-order">
          <div className="overlay-container">
            <Image src={Section3} fluid />
            <div className="overlay-centered-text">
              <h3 className="overlay-title">
                ORDER ONLINE
              </h3>
              <div>
                <p>
                  At Sushi Modo, freshness is our priority. We source the finest ingredients daily to ensure that every dish bursts with vibrant flavors and crisp textures. From the ocean to your plate, experience the difference that freshness makes in every bite.
                </p>
              </div>
              <div className='arrow-wrapper'>
                <Image src={Arrow} fluid />
              </div>
              <div>
                <a href="https://order.codefusion.tech/restaurants/NmljFgO1p1b0DnWwM4Lw/locations/Rj5VHmFLDXHw6ZbCMoFi" className="link-btn btn" target='_blank' rel="noreferrer">Online Order</a>
              </div>
            </div>
          </div>
        </Row>
        {/* Section4: Gallery */}
        <Row className="section4">
        {window.innerWidth < 992 ? (
                <>
                  <Gallery />
                </>
              ) : (
                <>
                  <Flow />
                </>
              )}
        </Row>
        <span className="scroll-down" id="contact-mobile" style={{visibility: 'hidden'}}></span>
        {/* Section5: Reservation */}
        <Row className="section5" id="contact">
          <Col>
            <div className="overlay-container">
              <Image src={Section5} fluid />
              <div className="overlay-centered-text">
                <h4>
                  Dine in & Order online
                </h4>
                <h2>
                  Come and Enjoy<br />
                  Freshest Japanese Cuisine
                </h2>
                <div>
                  <a href={MenuPDF} target="_blank" rel="noreferrer" className="link-btn btn">See Menu</a>
                </div>
              </div>
            </div>
          </Col>
          <Col className='map-container'>
            <Map />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default HomePage;