import './Footer.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Facebook from '../assets/Facebook.png';
import Instagram from '../assets/Instagram.png';

function Footer() {
  return (
    <>
      <footer className="py-3">
        <Container>
          <Row>
            <Col>
              <Row>
                <Col>
                  <h5>
                    Contact Us
                  </h5>
                  <p>
                    (604) 469 - 8585
                  </p>
                </Col>
                <Col>
                  <h5>
                    Address
                  </h5>
                  <p>
                    3017 St. Johns Street,<br />
                    Port Moody
                  </p>
                </Col>
                <Col>
                  <h5>
                    Opening Hours
                  </h5>
                  <p>
                    Mon - Thu, Sun:  11:30am - 9:00pm<br />
                    Fri, Sat:  11:30am - 11:30pm
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
                <a href="https://www.facebook.com/sushimodo.ca/" target='_blank' rel="noreferrer"><Image src={Facebook} style={{ width: "30px" }} /></a>
                <a href="https://www.instagram.com/sushimodo/" target='_blank' rel="noreferrer"><Image src={Instagram} style={{ width: "30px" }} /></a>
            </Col>
          </Row>
          <Row>
              <p id='copyright'><a href="https://www.agoraproduction.ca/" target='_blank' rel="noreferrer">©2024 by AGORA PRODUCTION.</a></p>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default Footer;